import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanLoad,
  CanActivate,
  CanActivateChild,
  Route,
  Router
} from '@angular/router';
import { BusinessServiceType } from '../modules/shared/enums/business-service-type';
import { CompanyService, ProfileService } from '../modules/shared/services';
import { ApprovalStatus } from '../modules/membership/modules/account/enums/approval-status';
import { UserRoleCode } from '../modules/membership/modules/account/models/user-role-code.enum';
import { InTrialAppraisalService } from '../modules/shared/services/intrial-appraisal.service';
import { take } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionGuard implements CanLoad, CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private companyService: CompanyService,
    private profileService: ProfileService,
    private inTrialAppraisalService: InTrialAppraisalService,
  ) { }

  canLoad(route: Route): Promise<boolean> | boolean {
    const { requiredSubscriptions } = route.data || <Array<BusinessServiceType>>[];
    return this.canProceed(requiredSubscriptions);
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> | boolean {
    const { requiredSubscriptions } = next.data || <Array<BusinessServiceType>>[];
    return this.canProceed(requiredSubscriptions);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> | boolean {
    const { requiredSubscriptions } = next.data || <Array<BusinessServiceType>>[];
    return this.canProceed(requiredSubscriptions);
  }

  private async canProceed(requiredSubscriptions: Array<BusinessServiceType>): Promise<boolean> {
    let canContinue = true;

    if (!this.companyService.companyExists) {
      try {
        this.companyService.company = await this.companyService.getCompanyProfile().toPromise();
      } catch (error) {
        console.log(error);
      }
    }

    if (this.profileService.person === null) {
      try {
        this.profileService.person = await this.profileService.get().toPromise();
        this.profileService.notifyPersonLoaded();
      } catch (error) {
        console.log(error);
      }
    }

    let hasInTrialSubscription = false;

    if (this.companyService.companyExists && !this.companyService.hasAppraisal) {
      try {
        const appraisalInTrialSubscription = await this.inTrialAppraisalService.getInTrialRestriction().pipe(take(1)).toPromise();
        hasInTrialSubscription = appraisalInTrialSubscription?.approvalStatus === ApprovalStatus.Approved &&
          appraisalInTrialSubscription?.isActive;
      } catch (error) {
        if (!environment.production) {
          console.log(error);
        }
      }
    }

    const isApproved = this.profileService.person.approvalStatus === ApprovalStatus.Approved;

    if (requiredSubscriptions.length > 0) {
      canContinue = requiredSubscriptions.some(s => {
        switch (s) {
          case BusinessServiceType.CarePlan:
            return this.companyService.hasCarePlan && isApproved;
          case BusinessServiceType.Shipping:
            return this.companyService.hasShipping && isApproved;
          case BusinessServiceType.CommercialInsurance:
            return this.companyService.hasBusinessInsurance && isApproved;
          case BusinessServiceType.Custom:
            return this.companyService.hasCustomService && isApproved;
          case BusinessServiceType.Appraisal:
            return (this.companyService.hasAppraisal && !this.companyService.hasPendingAppraisal && isApproved) || hasInTrialSubscription;
          case BusinessServiceType.MarketPlace:
            return this.companyService.hasMarketplace && isApproved;
          case BusinessServiceType.PointOfSaleIntegration:
            return (this.companyService.hasPointOfSaleIntegration && isApproved);
          case BusinessServiceType.Memo:
            return this.companyService.hasMemoSubscription && isApproved;
          case BusinessServiceType.JewelerPages:
            return this.companyService.hasActiveJewelerPagesSubscription && isApproved;
          default:
            return false;
        }
      });
    }

    if (!canContinue) {
      this.router.navigateByUrl('/forbidden');
    }

    return canContinue;
  }
}
