export enum BusinessServiceType {

  // Business offers Personal line insurance
  CarePlan = 1,

  // Business offers Shipping service
  Shipping  = 2 ,

  // Business offers Commercial line insurance
  CommercialInsurance = 3 ,

  // Business offers Personal line insurance
  PersonalInsurance = 4 ,

  // Business offers Partner gateway
  PartnerGateway = 5,

  // Business offers Appraisal of jewelry
  Appraisal = 6,

  // Business offers MarketPlace
  MarketPlace = 7,

  // Business offers Point of sale
  PointOfSaleIntegration= 8,

  // Business offers Memo
  Memo = 9,

  // Business offers Jeweler Pages
  JewelerPages = 11,

  // Business offers Auction
  Auction = 12,

  // Business service which is not defined on platform Yet
  Custom = 999
}
