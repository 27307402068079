/**
 * Environment variables for application deployed to DEV server instance on Azure.
 */

export const environment = {
  production: false,
  baseURL: 'https://dev-zing.jewelersmutual.com',
  carePlanAppUrl: 'https://jewelers-mutual.dev.dais.com',
  clientId: 'ng',
  reportingApiUrl: 'https://core-apimgmt-proxy.dev-platform.jewelersmutual.com/reporting',
  paymentApiURL: 'https://core-apimgmt-proxy.dev-platform.jewelersmutual.com/payment',
  identityApiURL: 'https://identity.dev-platform.jewelersmutual.com',
  membershipApiURL: 'https://core-apimgmt-proxy.dev-platform.jewelersmutual.com/membership',
  riskApiURL: 'https://core-apimgmt-proxy.dev-platform.jewelersmutual.com/businessrisk',
  shippingApiURL: 'https://core-apimgmt-proxy.dev-platform.jewelersmutual.com/shipping',
  billingApiURL: 'https://core-apimgmt-proxy.dev-platform.jewelersmutual.com/billing',
  communicationApiURL: 'https://core-apimgmt-proxy.dev-platform.jewelersmutual.com/communication',
  contentApiURL: 'https://core-apimgmt-proxy.dev-platform.jewelersmutual.com/content',
  accountApiUrl: 'https://core-apimgmt-proxy.dev-platform.jewelersmutual.com/account',
  quickBillPayLink: 'https://my.testjewelersmutual.com/QuickBillPay/?mixpanelsuppressinits=&productLine=CL',
  partnerGatewayLink: 'https://portal.dev.jewelersmutual.com',
  appraisalApiURL: 'https://core-apimgmt-proxy.dev-platform.jewelersmutual.com/appraisal',
  careplanApiURL: 'https://core-apimgmt-proxy.dev-platform.jewelersmutual.com/careplan',
  marketplaceApiURL: 'https://core-apimgmt-proxy.dev-platform.jewelersmutual.com/marketplace',
  pointOfSalesIntegrationApiUrl: 'https://core-apimgmt-proxy.dev-platform.jewelersmutual.com/posintegration',
  memoApiUrl: 'https://core-apimgmt-proxy.dev-platform.jewelersmutual.com/memo',
  configurationApiUrl: 'https://core-apimgmt-proxy.dev-platform.jewelersmutual.com/configuration',
  documentApiUrl: 'https://core-apimgmt-proxy.dev-platform.jewelersmutual.com/document',
  jewelerPagesApiUrl: 'https://core-apimgmt-proxy.dev-platform.jewelersmutual.com/jewelerpages',
  shoppingCartApiUrl: 'https://core-apimgmt-proxy.dev-platform.jewelersmutual.com/shoppingcart',
  jmIdentityLoginUrl: 'https://dev-identity.jewelersmutual.com',
  jmLoginActive: true,
  mockMarketData: true,
  authLogConsoleWarningActive: true,
  authLogConsoleDebugActive: false,
  authMaxTokenOffset: 600,
  paymentPeriod: 3,
  instrumentationKey: '07723f59-1390-48a4-a872-0d3c4a11dbab',
  feinIdValidationEnabled: false,
  apiManagement: {
    enableTrace: true,
    SubscriptionKey: 'd51017f0e3364caaab7f98041d40d5eb'
  },
  twilioPhoneNumber: '+18448909464',
  isOnlyOneCreditCardAllowed: true,
  fedExUrl: 'https://www.fedex.com/apps/fedextrack?trackingnumber=',
  upsUrl: 'http://www.ups.com/track?loc=en_US&tracknum=',
  uspsUrl: 'https://tools.usps.com/go/TrackConfirmAction_input?qtc_tLabels1=',
  uspsPickupUrl: 'https://tools.usps.com/schedule-pickup-steps.htm',
  dhlUrl: 'https://www.dhl.com/en/express/tracking.html?AWB=',
  uspsStoreUrl: 'https://store.usps.com/store/results/shipping-supplies/_/N-7d0v8v',
  uspsRegisterUrl: 'https://reg.usps.com/entreg/RegistrationAction_input',
  additionalSuppliesUrl: 'https://jminsure.co1.qualtrics.com/jfe/form/SV_5hliQOxGYUdu1PE',
  googleAnalyticsKey: 'UA-150594983-4',
  personalLinesUrl: 'https://pl-claims-hub.dev.dais-jm.com',
  gemAndJewelUrl: 'https://dev.gemandjewel.com/',
  gemAndJewelAccountUrl: 'https://dev.gemandjewel.com/integration/zing/autologin',
  disableIatOffsetValidation: false,
  carePlanHomePage: 'https://www.jewelersmutual.com/jeweler-care-plan',
  businessInsuranceDetails: 'https://www.jewelersmutual.com/jewelry-business-jewelers-block-bop-insurance',
  gtmId: 'GTM-TKWZP42',
  USCensusBureauUrl: 'https://uscensus.prod.3ceonline.com',
  eeiExemptionsPdfLink: 'https://dev.transguardian.com/documents/EEIExceptions.pdf',
  eStoreLink: 'https://dev.transguardian.com/estore',
  insuranceQuoteUrl: 'https://ioi-jm.uat.dais-jm.com/in/',
  ishipAmlBundle: 'https://dev.transguardian.com/ClientServices/PAC/Bundle.aspx',
  ishipAmlTraining: 'https://dev.transguardian.com/ClientServices/PAC/Session.aspx',
  // eslint-disable-next-line max-len
  oldInsuranceQuoteUrl: 'https://my.jewelersmutual.com/jewelry-insurance-quote-apply/t/DCZdhSmEH0ubnHs4nz2aSQ?_ga=2.141724530.1703023056.1588686519-874137592.1583941308',
  landingPageUrl: 'https://dev-zing.jewelersmutual.com/login',
  privacyPolicyUrl: 'https://stage.jewelersmutual.com/privacy-policy',
  customerLoyaltyProgramUrl: 'https://www.jewelersmutual.com/customer-loyalty-programs-for-jewelers',
  shoppingCart: {
    maxShoppingCartItems: 15
  },
  compareDiamonds: {
    maxCompareItems: 5
  },
  stripeApiKey: 'pk_test_51I2NK8Gc5vQ30lOSoQh2eXiz9GCQqdvD6deolgun3lH5aGC1d14Ce4sYGgXRwn9oPt8fSM85axQaJzwvouL8mTrd00FBGVJEUd',
  supplierProvidedSourceEnabled: true,
  showAgsAffiliationBanner: false,
  adsId: '1636741576988',
  slotName1: '/158056972/jm_zing_platform_970x180_test',
  slotName2: '/158056972/jm_zing_platform_674x674_test',
  excludeIndexing: true,
  mixPanelApiKey:'dcf96bbfd80824c91182a678abf28ade'
};
