import { environment } from '../../environments/environment';

const shippingApiDomain = environment.shippingApiURL;

export const encodingConfig: { fields: string[]; url: string }[] = [
  {
    url: '/api/claims/submit',
    fields: [
      'contact',
      'emailAddress',
      'company',
      'packageContents',
      'outerPackagingDamage',
      'innerPackagingDamage',
      'innerPackagingDescription',
      'policeReportInformation',
      'lossCircumstances',
      'comment'
    ]
  },
  {
    url: '/api/supplyorders',
    fields: [
      'comments',
      'company',
      'firstName',
      'lastName',
      'referenceName'
    ]
  },
  {
    url: `${shippingApiDomain}/api/shipments`,
    fields: [
      'name',
      'firstName',
      'lastName',
      'company',
      'referenceName',
      'insuranceOnlyTrackingNumber',
      'receiverReference',
      'senderReference',
      'sealNumber',
      'approvalCode'
    ]
  },
  {
    url: '/api/quotes',
    fields: [
      'firstName',
      'lastName',
      'company',
      'referenceName',
      'insuranceOnlyTrackingNumber'
    ]
  },
  {
    url: '/api/shippings/presets',
    fields: [
      'name',
      'firstName',
      'lastName',
      'company',
      'referenceName'
    ]
  },
  {
    url: `${shippingApiDomain}/api/addresses`,
    fields: [
      'referenceName',
      'firstName',
      'lastName',
      'company'
    ]
  },
  {
    url: '/billingreview',
    fields: [
      'comment'
    ]
  },
  {
    url: '/api/estores/defaultshipmentvalues',
    fields: [
      'company',
      'contact',
      'addressLine1',
      'addressLine2,',
      'city',
      'emailAddress',
      'state',
      'zip'
    ]
  },
  {
    url: 'api/pickup/ups/schedule',
    fields: [
      'firstName',
      'lastName',
      'company'
    ]
  },
  {
    url: 'api/pickup/fedex/schedule',
    fields: [
      'firstName',
      'lastName',
      'company'
    ]
  },
  {
    url: '/api/usersettings/integratefedexaccount',
    fields: [
      'accountName'
    ]
  },
  {
    url: '/notes',
    fields: [
      'specialInstructionText'
    ]
  },
  {
    url: '/api/discounts/validate',
    fields: [
      'code'
    ]
  }
];
